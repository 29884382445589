import React, { useState } from "react";
import styled from "styled-components";
import { colors, glass } from "../utils/utils";
import { useGlobalContext } from "./context";
import Div100vh from "react-div-100vh";
import { animated, useSpring, config } from "react-spring";
import { fadeIn } from "../components/styles";

const Wrapper = styled(Div100vh)`
  /* background:${colors.stoneWhite}; */
  /* background: rgba(247, 245, 234,0.3);
    backdrop-filter: blur(10px); */
  width: 100vw;
  color: ${colors.stoneBlack};
  animation-delay: 1s;
  ${fadeIn};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border-top:1px dotted ${colors.stoneWhite}; */
`;

const Animated = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  ${glass};
`;
const Button = styled.button`
  background: none;
  border: 1px dotted ${colors.stoneBlack};
  padding: 15px 20px;
  border-radius: 5px;
  margin: 10px;
  min-width: 150px;
  cursor: pointer;
  background: ${colors.stoneBlack};
  color: ${colors.stoneWhite};
  padding: 10px 10px;
  text-transform: uppercase;
  min-width: 120px;
  margin-top: 1rem;
  &:hover {
    background: none;
    /* border: 1px solid ${colors.stoneBlack}; */
    color: ${colors.stoneBlack};
  }
`;

const Warning = () => {
  const { isFirstTime, SetIsFirstTimetoFalse } = useGlobalContext();
  const [isVisible, setIsVisible] = useState(isFirstTime);
  const warningSpring = useSpring({
    opacity: isFirstTime ? 1 : 0,
    config: config.slow,
    onRest: () => handleReset(),
  });

  const handleReset = () => {
    if (!isFirstTime) {
      setIsVisible(false);
    }
  };

  React.useEffect(() => {
    setIsVisible(true);
  }, [isFirstTime]);

  return (
    <>
      {isVisible && (
        <Animated style={warningSpring}>
          <Wrapper>
            <h3> Are you 21 or older?</h3>
            <div>
              <Button onClick={SetIsFirstTimetoFalse}>yes</Button>
              <a href="https://www.google.com">
                <Button>no</Button>
              </a>
            </div>
          </Wrapper>
        </Animated>
      )}
    </>
  );
};

export default Warning;
