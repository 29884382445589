import React from "react";
import { HiOutlineArrowRight } from "react-icons/hi";
import styled from "styled-components";
import { Link } from "gatsby";

const LinkWrapper = styled.a`
  display: ${(props) => (props.isinline ? "inline" : "flex")};
  justify-content: space-between;
`;

const GatsbyLink = styled(Link)`
  display: ${(props) => (props.isinline ? "inline" : "flex")};
  justify-content: space-between;
`;

const Arrow = styled(HiOutlineArrowRight)`
  position: relative;
  color: ${(props) => props.theme.stoneAccent};
  top: 2px;
  margin-left: 0.5rem;
  transition: transform 0.25s;

  ${LinkWrapper}:hover & {
    transform: rotate(-45deg);
  }
  ${GatsbyLink}:hover & {
    transform: rotate(-45deg);
  }
  transform: ${(props) => (props.hover ? "rotate(-45deg)" : "rotate(0deg)")};
`;

const MyLink = ({ to, internal, children, inline, forcehover }) => {
  if (to === undefined) {
    return (
      <GatsbyLink as="span" isinline={inline ? 1 : 0}>
        {children}
        <Arrow hover={forcehover} />
      </GatsbyLink>
    );
  }

  if (internal) {
    return (
      <GatsbyLink to={to} isinline={inline ? 1 : 0}>
        {children}
        <Arrow forcehover={forcehover} />
      </GatsbyLink>
    );
  }

  return (
    <LinkWrapper
      href={to}
      target="_blank"
      rel="noopener"
      isinline={inline ? 1 : 0}
    >
      {children}
      <Arrow forcehover={forcehover} />
    </LinkWrapper>
  );
};

export default MyLink;
