import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { backgroundBlur, springs } from "../utils/utils";
import { MdClose } from "react-icons/md";
import { motion } from "framer-motion";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.glass};
  z-index: 100;
  ${backgroundBlur};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100svh - 2rem);
  width: calc(100svw - 2rem);
  position: fixed;
  margin: 1rem;
  z-index: 101;
  top: 0;
  color: rgb(10, 10, 10);

`;

const Modal = styled.div`
  position: relative;
  width: 100%;
  background: white;
  border-radius: 1rem;
  overflow: scroll;
  padding:20px;
  height: calc(100svh - 80px);
  max-width: 700px;

  border: 1px solid ${(props) => props.theme.stoneBlack};
  
  line-height: 1.5;

  a {
    text-transform: none;

    border-bottom: 1px dotted black;
    letter-spacing: normal;
  }

  header {
    display: flex;
    justify-content: space-between;
  }
  form {
    display: flex;
    width: 100%;
    input {
      background: none;
      box-shadow: none;
      border: none;
      width: 100%;
      text-transform: uppercase;
      letter-spacing: 1px;
      ::placeholder {
        opacity: 0.5;
      }
    }
  }

  .inputGroup {
    border-bottom: 1px dotted rgba(0, 0, 0, 1);
    margin-top: 2rem;
    padding-bottom: 0.4rem;
    display: flex;
    line-height: 1.4;
    svg {
      color: ${(props) => props.theme.stoneAccent};
    }
  }
`;

const Close = styled(MdClose)`
  position: absolute;
  right: 15px;
  top: 15px;

  width: 22px;
  height: 22px;
  cursor: pointer;
`;

export const ShopFinder = (
  { setIsOpen }
) => {

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://askhoodie.com/assets/askhoodie.host.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hoodieEmbedWtbV2) {
        window.hoodieEmbedWtbV2(
          "8895a665-dff2-49a2-80c0-59390768dd4a",
          "askhoodieDiv",
          document.cookie
        );
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);



  return (
    <>
      <Backdrop onClick={handleClose} onKeyDown={(e) => e.key === 'Escape' && handleClose()} tabIndex={0} role="button" />
      <Wrapper>
        <Modal
          as={motion.div}
          variants={wrapperVariants}
          initial="init"
          animate="anim"
        >
          <Close onClick={handleClose} aria-label="Close" />
          <div id="askhoodieDiv"></div>
        </Modal>
      </Wrapper>
    </>
  );
};

//motion

const wrapperVariants = {
  init: {
    y: 30,
    rotate: 0,
    opacity: 0,
  },
  anim: {
    y: 0,
    rotate: 0,
    opacity: 1,
    transition: {
      ...springs.ease,
    },
  },
};
