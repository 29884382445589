import styled, { keyframes, css } from "styled-components";
import { device } from "../utils/utils";

export const Page = styled.div`
  margin-top: 40px;
  min-height: 100vh;
  padding: ${(props) => (props.padding ? "1rem" : "0")};
  padding-bottom: 2rem;
`;

export const Container = styled.div`
  max-width: ${(props) => (props.wide ? "1000px" : "750px")};
  margin: auto;
  padding: 1rem;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 750px 1fr;
  margin-top: 1rem;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

const fadeUpAnimation = keyframes`
  from {
    top: 10px;
    opacity:0;
  }
  to {
    top: 0px;
    opacity:1;
  }
`;

const fadeInAnimation = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

export const fadeUp = css`
  position: relative;
  animation: ${fadeUpAnimation} 0.5s ease-in;
`;

export const fadeIn = css`
  animation: ${fadeInAnimation} 0.5s ease-in;
`;
