import React from "react";
import Layout from "../components/layout";
import { ContextProvider } from "../components/context";

export const RootElement = (props) => {
  // console.log(props);
  const { location } = props;

  return (
    <ContextProvider uri={location.pathname}>
      <Layout uri={location.pathname}>{props.children}</Layout>
    </ContextProvider>
  );
};
