import React from "react";
import styled from "styled-components";
import { backgroundBlur, springs } from "../utils/utils";
import { MdClose } from "react-icons/md";
import { motion } from "framer-motion";
// import addToMailchimp from "gatsby-plugin-mailchimp";
import MyLink from "../components/mylink";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.glass};
  z-index: 9;
  ${backgroundBlur};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  color: rgb(10, 10, 10);
`;

const Modal = styled.div`
  position: relative;
  width: 300px;
  background: #fefae1;
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  margin: 1rem;
  border: 1px solid ${(props) => props.theme.stoneBlack};
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5;

  a {
    text-transform: none;

    border-bottom: 1px dotted black;
    letter-spacing: normal;
  }

  header {
    display: flex;
    justify-content: space-between;
  }
  form {
    display: flex;
    width: 100%;
    input {
      background: none;
      box-shadow: none;
      border: none;
      width: 100%;
      text-transform: uppercase;
      letter-spacing: 1px;
      ::placeholder {
        opacity: 0.5;
      }
    }
  }

  .inputGroup {
    border-bottom: 1px dotted rgba(0, 0, 0, 1);
    margin-top: 2rem;
    padding-bottom: 0.4rem;
    display: flex;
    line-height: 1.4;
    svg {
      color: ${(props) => props.theme.stoneAccent};
    }
  }
`;

const Close = styled(MdClose)`
  position: absolute;
  right: 15px;
  top: 15px;

  width: 22px;
  height: 22px;
  cursor: pointer;
`;

const Subscribe = ({ setSubscribe }) => {
  const [result, setResult] = React.useState({
    msg: "Get to know us!",
  });
  const [email, setEmail] = React.useState("");

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const options = {
      method: "POST",
      headers: {
        Accept: "text/html",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        data: `{"token": "RHJBsJ","properties": {"$email":"${email}","$consent":"['email']"}}`,
      }),
    };

    fetch("https://a.klaviyo.com/api/identify", options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setResult({ msg: "Thank you" });
        setTimeout(handleClose, 2000);
      })
      .catch((err) => setResult({ msg: err }));

    console.log(result);
  };

  React.useEffect(() => {
    if (result.result === "success") {
      setTimeout(function () {
        handleClose();
      }, 2000);
    } else {
      shake();
    }
  }, [result]);

  const shake = () => {};

  const handleClose = () => {
    setSubscribe(false);
  };

  return (
    <>
      <Backdrop onClick={handleClose} />
      <Wrapper>
        <Modal
          as={motion.div}
          variants={wrapperVariants}
          initial="init"
          animate="anim"
        >
          <Close onClick={handleClose} />

          <div>
            <motion.div
              variants={wrapperVariants}
              initial="init"
              animate="anim"
              dangerouslySetInnerHTML={{ __html: result.msg }}
            />
            <div className="inputGroup">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div onClick={handleSubmit}>
                  <MyLink />
                </div>

                <button style={{ display: "none" }} type="submit"></button>
              </form>
            </div>
          </div>
        </Modal>
      </Wrapper>
    </>
  );
};

export default Subscribe;

//motion

const wrapperVariants = {
  init: {
    y: 30,
    rotate: 0,
    opacity: 0,
  },
  anim: {
    y: 0,
    rotate: 0,
    opacity: 1,
    transition: {
      ...springs.ease,
    },
  },
};
