const React = require("react");

const { RootElement } = require("./src/components/rootElement");

exports.onRouteUpdate = ({ location }) => {
  //   console.log("new pathname", location.pathname);
};

exports.wrapPageElement = ({ props, element }) => {
  return <RootElement {...props}>{element}</RootElement>;
};
