import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = () => {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        seo {
          keywords
          description
          title
          ogImage {
            asset {
              fixed(height: 627, width: 1200) {
                src
              }
            }
          }
        }
      }
    }
  `);

  const {
    sanitySiteSettings: { seo },
  } = data;

  return (
    <Helmet>
      <title>{seo.title} </title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      {/* <meta property="og:url" content={ogUrl} /> */}
      {/* <meta property="og:type" content={props.type} /> */}
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.ogImage.asset.fixed.src} />
      {/* <script type="application/ld+json">
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "http://www.stoneroad.org",
            "logo": "http://www.example.com/images/logo.png"
          }
        </script> */}
    </Helmet>
  );
};

export default Seo;
