import React from "react";
import styled from "styled-components";
import { border, device } from "../utils/utils";
import { useStaticQuery, graphql } from "gatsby";
import {
  AiFillInstagram,
  AiOutlineMedium,
  AiFillMail,
  AiFillHeart,
} from "react-icons/ai";
import MyLink from "../components/mylink";

const Wrapper = styled.div`
  background: ${(props) => props.theme.stoneLightGray};
  height: fit-content;
  padding: 2rem;
  text-align: justify;
  line-height: 2;
  @media ${device.tablet} {
    padding: 1rem;
  }
  .contact {
    display: flex;
    justify-content: space-between;

    @media ${device.tablet} {
      flex-flow: column;
      text-align: left;
    }
  }

  svg {
    position: relative;
    top: 4px;
    width: 20px;
    height: 20px;
  }

  a {
    margin-left: 0.5rem;
    margin-right: 1.5rem;

    &:hover {
      opacity: 0.5;
    }

    @media ${device.tablet} {
      display: flex;
      margin-left: 0rem;
      margin-right: 0rem;
      margin-top: 1rem;
      border-bottom: ${border};
      padding-bottom: 0.5rem;
      border-color: ${(props) => props.theme.stoneBlack};
    }
  }

  .credits {
    opacity: 0.33;
    margin-right: 2rem;
    font-size: 0.8rem;
    text-align: right;
    letter-spacing: 2px;
    a {
      margin-left: 0;
      margin-right: 0;
    }
    svg {
      width: 15px;
    }

    @media ${device.tablet} {
      order: 2;
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-right: none;
      font-size: 0.6rem;
      width: 100%;
      a {
        display: inline-block;
        border: none;
      }
    }
  }

  .icons {
    @media ${device.tablet} {
      margin-top: 1rem;
      display: flex;
      justify-content: space-evenly;
      a {
        display: inline-block;
        border-bottom: none;
      }
    }
  }

  .floatRight {
    display: flex;
    @media ${device.tablet} {
      flex-direction: column;
    }
  }

  .hiddenMobile {
    @media ${device.tablet} {
      display: none;
    }
  }

  .showMobile {
    @media ${device.tablet} {
      display: initial;
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        email
        instagram
        medium
      }
    }
  `);

  const {
    sanitySiteSettings: { email, instagram, medium },
  } = data;

  return (
    <Wrapper>
      <div className="contact">
        <div>
          <MyLink internal inline to="/">
            Home
          </MyLink>
          <MyLink internal inline to="/products">
            Products
          </MyLink>
          <MyLink internal inline to="/farm">
            Farm
          </MyLink>
          <MyLink internal inline to="/press">
            Press{" "}
          </MyLink>
        </div>
        <div className="floatRight">
          <div className="credits">
            MADE WITH <AiFillHeart /> BY{" "}
            <a
              href="https://www.madeatartcamp.com/"
              target="_blank"
              rel="noreferrer"
            >
              Art Camp
            </a>{" "}
            &{" "}
            <a href="https://wip.llc/" target="_blank" rel="noreferrer">
              WIP.llc{" "}
            </a>
          </div>
          <div className="icons">
            <a href={`mailto:${email}`} className="showMobile">
              <AiFillMail />
            </a>
            <a href={instagram}>
              <AiFillInstagram />
            </a>
            <a href={medium}>
              <AiOutlineMedium />
            </a>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
