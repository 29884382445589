import React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { Link, navigate } from "gatsby";
import Div100vh from "react-div-100vh";
import { AiFillCaretDown } from "react-icons/ai";
import { colors, device, barSize, border } from "../utils/utils";
import { useGlobalContext } from "../components/context";
import bird from "../images/gifs/bird-small.gif";
import bloom from "../images/gifs/bloom-small.gif";
import bunny from "../images/gifs/bunny-small.gif";
import butterfly from "../images/gifs/butterfly-small.gif";
import { useStaticQuery, graphql } from "gatsby";
import { AiFillInstagram } from "react-icons/ai";
import { WiMoonAltWaningCrescent3 } from "react-icons/wi";
import MyLink from "../components/mylink";


let navHeight = barSize;

const Nav = styled.nav`
  position: fixed;
  top: ${(props) => (props.navUp ? "0px" : `calc(100% - ${navHeight})`)};
  left: 0px;
  width: 100vw;
  text-align: center;
  z-index: 9;
  color: ${colors.stoneBlack};
  color: ${(props) => props.theme.stoneBlack};
  text-transform: uppercase;
  pointer-events: none;
  letter-spacing: 2px;
  transition: top 0.6s;

  .raise {
    transform: translateY(-25px);
  }
`;

const Bar = styled.div`
  background: ${(props) => props.theme.stoneWhite};
  background: ${(props) => props.theme.glass};
  backdrop-filter: blur(10px);
  width: 100%;
  height: ${navHeight};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  border-top: 1px dotted ${(props) => props.theme.stoneBlack};
  border-bottom: 1px dotted ${(props) => props.theme.stoneBlack};
  transition: background 0.5s;
  pointer-events: bounding-box;
  &:hover {
    background: ${(props) => props.theme.stoneYellow};
    svg {
      color: ${(props) => props.theme.stoneGreen};
    }
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: calc(100% - ${navHeight});
  position: relative;
  top: ${(props) => (props.drawerOpen ? "-100%" : `0`)};
  pointer-events: bounding-box;
  border-bottom: ${border};
  transition: top 1s;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const MenuItem = styled.div`
  border-right: ${border};
  border-color: ${(props) => props.theme.stoneBlack};
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${(props) => props.theme.stoneWhite};
  cursor: pointer;
  transition: background-color 1s;

  @media ${device.tablet} {
    border-right: none;
    border-bottom: ${border};
    border-color: ${(props) => props.theme.stoneBlack};
  }

  &:hover {
    background: ${(props) => props.theme.stoneYellow};
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    transition: transform 0.5s;

    :hover {
      transform: scale(1.1);
    }

    img {
      width: auto;
      height: 150px;
      margin-bottom: 1rem;
      filter: ${(props) =>
    props.theme.name === "dark" ? "grayscale(1)" : "none"};

      @media ${device.tablet} {
        height: 60px;
      }
    }
  }
`;

const InstagramIcon = styled.a`
  float: right;
  position: absolute;
  right: 1rem;
`;

const DayNightIcon = styled(WiMoonAltWaningCrescent3)`
  float: left;
  position: absolute;
  left: 1rem;
`;

const Drawer = ({ setShopFinderOpen }) => {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        instagram
        proper
        merch
      }
    }
  `);

  const {
    sanitySiteSettings: { instagram, proper, merch },
  } = data;

  const {
    toggleTheme,
    uri,
    drawerOpen,
    navUp,
    openDrawer,
    closeDrawer,
    sendNavUp,
    sendNavDown,
  } = useGlobalContext();
  const arrowSpring = useSpring({
    transform: navUp ? `rotate(180deg)` : `rotate(0deg)`,
  });


  function handleClick(e) {
    if (uri === "/") {
      if (navUp) {
        sendNavDown();
      } else {
        sendNavUp();
      }
    } else {
      if (!drawerOpen) {
        navigate("/");
        sendNavDown();
      } else {
        toggleMenu();
      }
    }
  }

  function toggleMenu() {
    if (drawerOpen) {
      closeDrawer();
    } else {
      openDrawer();
    }
  }

  const handleThemeBtn = (e) => {
    e.stopPropagation();
    toggleTheme();
  };

  return (
    <Nav navUp={navUp}>

      <Div100vh>
        <Bar onClick={handleClick}>
          <DayNightIcon onClick={handleThemeBtn} />
          <animated.div style={arrowSpring}>
            <AiFillCaretDown />
          </animated.div>
          <InstagramIcon
            href={instagram}
            target="_blank"
            rel="noopener"
            alt="Stone Road Instagram"
            onClick={(e) => e.stopPropagation()}
          >
            <AiFillInstagram />
          </InstagramIcon>
        </Bar>
        <Menu drawerOpen={drawerOpen}>
          <MenuItem>
            <Link onClick={openDrawer} to={"/farm/"}>
              <img
                src={butterfly}
                alt="butterfly gif"
                height="150px"
                width="150px"
              ></img>
              Our Story
            </Link>
          </MenuItem>
          <MenuItem>
            <Link onClick={openDrawer} to={"/products/"}>
              <img
                src={bloom}
                alt="flower blooming gif"
                height="150px"
                width="150px"
              ></img>
              Products
            </Link>
          </MenuItem>
          <MenuItem>
            <a onClick={() => setShopFinderOpen(true)}>
              <img
                src={bunny}
                alt="bunny gif"
                height="150px"
                width="217px"
              ></img>
              <MyLink>Find Store</MyLink>
            </a>
          </MenuItem>
          <MenuItem>
            <a href={merch} target="_blank" rel="noreferrer">
              <img src={bird} alt="bird gif" height="150px" width="150px"></img>
              <MyLink>
                <span>Merch</span>
              </MyLink>
            </a>
          </MenuItem>
        </Menu>
      </Div100vh>

    </Nav>
  );
};

export default Drawer;
