import React from "react";
import { useGlobalContext } from "../components/context";
import Warning from "../components/Warning";
import Helmet from "react-helmet";
import { useSwipeable } from "react-swipeable";
import Footer from "./Footer";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { device } from "../utils/utils";
import { reset } from "../utils/reset";
import Drawer from "./drawer";
import loadable from "@loadable/component";
import SEO from "./seo";
import Subscribe from "./subscribe";
import styled from "styled-components";
import { ShopFinder } from "../components/ShopFinder";
import "normalize.css";
import "../utils/typography.css";

const FlyingAnimal = loadable(() => import("../components/FlyingAnimal"));

const defaultTheme = {
  name: "default",
  stoneGreen: "#0C633E",
  stoneWhite: "#F7F5E9",
  stoneLightGray: "#EBEBEB",
  stoneBlack: "#222222",
  stoneGrey: "#999999",
  stoneYellow: "#FFFADE",
  stoneAccent: "#D0A200",
  glass: "rgba(247, 245, 234, 0.4)",
};

const darkTheme = {
  name: "dark",
  stoneGreen: "#EEEEEE",
  stoneWhite: "#121212",
  stoneLightGray: "#000000",
  stoneBlack: "#BBB",
  stoneGrey: "#999999",
  stoneYellow: "#202020",
  stoneAccent: "#FFF",
  glass: "rgba(0, 0, 0, 0.4)",
};

const GlobalStyle = createGlobalStyle`
    ${reset}

    html {
        background:${(props) => props.theme.stoneWhite};
        color: ${(props) => props.theme.stoneBlack};
        text-rendering: geometricprecision;
        transition: background-color 1.5s;
    }
 
    a, a:hover, a:focus, a:active {
        text-decoration: none;
        color: inherit;
        text-transform:uppercase;
        line-height:1.2;
        letter-spacing:2px;
    }

    img{
        /* filter: ${(props) =>
    props.theme.name === "dark" ? "grayscale(1)" : "none"}; */
    }

    body{
    font-family: Passport,'Courier New', Courier, monospace;
    }

    p {
        line-height: 2;
        /* margin-top:1rem; */
        margin-bottom:1rem;
        letter-spacing:1px;
    }

    h1,h2,h3,h4 {
        text-transform:uppercase;
        line-height:1.2;
        letter-spacing:1px;
    }

    .hiddenOnMobile{
        @media ${device.tablet} {
            display:none;
        }
    }
`;

const Page = styled.div`
  opacity: ${(props) => (props.faded ? "100%" : "0%")};
  transition: opacity 1s;
`;

const Layout = ({ uri, children }) => {
  const [pageVisible, setPageVisible] = React.useState(true);

  const {
    hasInteracted,
    setInteractedToTrue,
    darkMode,
    sendNavUp,
    navUp,
    drawerOpen,
    sendNavDown,
    openDrawer,
  } = useGlobalContext();
  let hasFooter = true;

  const [subscribe, setSubscribe] = React.useState(false);
  const [shopFinderOpen, setShopFinderOpen] = React.useState(false);

  React.useEffect(() => {
    let timeout = setTimeout(() => {
      setSubscribe(true);
    }, 9000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  switch (uri) {
    case "/":
      hasFooter = false;
      break;
    case "/farm":
      break;
    default:
  }

  React.useEffect(() => {
    if (uri === "/") {
      setPageVisible(!navUp);
    } else {
      setPageVisible(drawerOpen);
    }
  }, [uri, navUp, drawerOpen]);

  React.useEffect(() => {
    setPageVisible(true);
  }, [uri]);

  let theme;
  if (darkMode) {
    theme = darkTheme;
  } else {
    theme = defaultTheme;
  }

  const handleWheel = (event) => {
    handleWheelAndTouch(event.deltaY);
  };

  const handleClick = () => {
    if (!hasInteracted) {
      setInteractedToTrue();
    }
  };
  const handleWheelAndTouch = (deltaY) => {
    if (uri === "/") {
      if (deltaY > 50 && !navUp) {
        sendNavUp();
      }
      if (deltaY < -50 && navUp) {
        sendNavDown();
      }
    } else {
      if (deltaY > 50 && !drawerOpen) {
        openDrawer();
      }
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedUp: (eventData) => handleWheelAndTouch(-eventData.deltaY / 2),
    onSwipedDown: (eventData) => handleWheelAndTouch(-eventData.deltaY / 2),
  });

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <script
          async
          src="https://aph-uploads-production.s3.amazonaws.com/connect.js"
        ></script>
      </Helmet>
      <SEO />

      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <main onWheel={handleWheel} onClick={handleClick} {...swipeHandlers}>
          <Warning />
          <Drawer setShopFinderOpen={setShopFinderOpen} />
          <Page faded={pageVisible}>
            {React.Children.map(children, child =>
              React.cloneElement(child, { setShopFinderOpen })
            )}
          </Page>
        </main>
        <FlyingAnimal />

        {subscribe && <Subscribe setSubscribe={setSubscribe} />}
        {hasFooter && <Footer />}
        {shopFinderOpen && <ShopFinder setIsOpen={setShopFinderOpen} />}
      </ThemeProvider>
    </>
  );
};

export default Layout;
