import React, { useContext, useState } from "react";

const defaultState = {
  isFirstTime: true,
  SetIsFirstTimetoFalse: () => {},
  drawerOpen: false,
  navUp: true,
  openDrawer: () => {},
  closeDrawer: () => {},
  sendNavDown: () => {},
  sendNavUp: () => {},
  // uri: '/',
  darkMode: false,
  hasInteracted: false,
};

const getLocalStorage = (key) => {
  if (typeof window !== `undefined`) {
    let storage = localStorage.getItem(key);
    return storage;
  }
};

const MyContext = React.createContext(defaultState);

const ContextProvider = ({ uri, children }) => {
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(uri !== "/");
  const [navUp, setNavUp] = useState(uri !== "/");
  const [darkMode, setDarkMode] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  const SetIsFirstTimetoFalse = () => {
    localStorage.setItem("secondTime", true);
    setIsFirstTime(false);
  };

  const openDrawer = () => {
    setDrawerOpen(true);
    document.body.style.overflow = "visible";
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
    document.body.style.overflow = "hidden";
  };
  const sendNavUp = () => setNavUp(true);
  const sendNavDown = () => setNavUp(false);
  const toggleTheme = () => {
    let newTheme = !darkMode;
    setDarkMode(newTheme);
  };

  const setInteractedToTrue = () => {
    if (hasInteracted === false) {
      setHasInteracted(true);
    }
  };

  React.useEffect(() => {
    const timeout = setTimeout(function () {
      setNavUp(uri !== "/");
    }, 1000);
    setDrawerOpen(uri !== "/");
    return () => {
      clearTimeout(timeout);
    };
  }, [uri]);

  React.useEffect(() => {
    let secondTime = getLocalStorage("secondTime");

    if (!secondTime) {
      setIsFirstTime(true);
    }
  }, []);

  return (
    <MyContext.Provider
      value={{
        isFirstTime,
        SetIsFirstTimetoFalse,
        drawerOpen,
        navUp,
        openDrawer,
        closeDrawer,
        sendNavDown,
        sendNavUp,
        uri,
        darkMode,
        toggleTheme,
        hasInteracted,
        setInteractedToTrue,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(MyContext);
};

export { MyContext, ContextProvider };
